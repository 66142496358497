$(() => {
  $('body').addClass('state--nav-closed')

  $('.c-global-nav-toggle').click(function (e) {
    e.preventDefault()
    $('body').toggleClass('state--nav-closed')
  })

  // Move the location of the additional events select dropdown
  $('.tn-additional-events__select-view-container .input-group').detach().appendTo('.tn-ticketing-mode-change')

  // Remove required labels following amount fields
  $('.tn-amount-field-label').next('.tn-required-field').remove()
})
